
import { computed, defineComponent, onMounted, ref } from "vue";
import BoxEdit from "@/views/main/ticketDetails/parts/BoxEdit.vue";
import { setModal } from "@/core/helpers/config";

import { useI18n } from "vue-i18n";
import { supabase } from "@/service/SupabaseService";
import TicketDevelopmentCard from "./TicketDevelopmentCard.vue";
import ProgerssBar from "../../../../components/shared/progerssBar.vue";
export default defineComponent({
  props: {
    skills: Array,
    type: String,
    relatesPage: String,
    relatesSection: String,
    assignedUser: Object,
    show: Number,
    timeSpent: Number,
    estimation: Number,
    createdAt: Date,
  },
  computed: {
    skillsLocal: {
      get: function () {
        return this.skills;
      },
      set: function (value) {
        this.$emit("update:skills", value);
      },
    },
    relatesPageLocal: {
      get: function () {
        return this.relatesPage;
      },
      set: function (value) {
        this.$emit("update:relatesPage", value);
      },
    },
    relatesSectionLocal: {
      get: function () {
        return this.relatesSection;
      },
      set: function (value) {
        this.$emit("update:relatesSection", value);
      },
    },
  },
  components: {
    BoxEdit,
    TicketDevelopmentCard,
    ProgerssBar,
  },
  setup(props) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    const showModel = (modalName) => {
      setModal(modalName);
    };
    const pages = [
      {
        id: "DF-P1",
        title: "ticket of page number 1",
      },
      {
        id: "DF-P2",
        title: "ticket of page number 2",
      },

      {
        id: "DF-P3",
        title: "ticket of page number 3",
      },
      {
        id: "DF-P4",
        title: "ticket of page number 4",
      },
      {
        id: "DF-P5",
        title: "ticket of page number 5",
      },
    ];
    const sections = [
      {
        id: "DF-S1",
        title: "ticket of page section 1",
      },
      {
        id: "DF-S2",
        title: "ticket of page section 3",
      },

      {
        id: "DF-S3",
        title: "ticket of page section 3",
      },
      {
        id: "DF-S4",
        title: "ticket of page section 4",
      },
      {
        id: "DF-S5",
        title: "ticket of page section 5",
      },
    ];
    const progressPercentage = (partialValue, totalValue, reversed = 0) => {
      return `${Math.abs(reversed - partialValue / totalValue) * 100}`;
    };
    const timeTrackingItems = computed(() => [
      {
        label: "estimated",
        value: progressPercentage(props.estimation, props.timeSpent),
        color: "primary",
        time: `${props.estimation}h`,
      },
      {
        label: "remaining",
        reversed: true,
        value: progressPercentage(props.timeSpent, props.estimation, 1),
        color: "info",
        time: `${
          props.estimation &&
          props.timeSpent &&
          props.estimation - props.timeSpent
        }h`,
      },
      {
        label: "logged",
        value: progressPercentage(props.timeSpent, props.estimation),
        color: "success",
        time: `${props.timeSpent}h`,
      },
    ]);

    const skillsFullList = ref();
    onMounted(() => {
      supabase
        .from("skills")
        .select("id, name")
        .then(({ data }) => {
          skillsFullList.value = data;
        });
    });

    return {
      translate,
      pages,
      sections,
      timeTrackingItems,
      skillsFullList,
      showModel,
    };
  },
});
