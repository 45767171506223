
import { formatBytes } from "@/core/helpers/fileHelper";
import { computed, defineComponent } from "@vue/runtime-core";
/* eslint-disable  @typescript-eslint/no-explicit-any */

export default defineComponent({
  props: {
    value: Object,
  },
  setup(props, ctx) {
    const computedValue = computed<Array<any>>({
      get() {
        return props.value as Array<any>;
      },
      set(val) {
        ctx.emit("update", val);
      },
    });
    const removeFile = (file) => {
      console.log(file);
      const newList = computedValue.value.filter(
        (item) => item.uid !== file.id
      );
      computedValue.value = newList;
      //uploadInput.value?.handleRemove(file);
    };
    return { formatBytes, removeFile };
  },
});
