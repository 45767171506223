
import { supabase } from "@/service/SupabaseService";
import { definitions } from "@/types/supabase";
import { defineComponent, ref } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import BoxEdit from "../../views/main/ticketDetails/parts/BoxEdit.vue";

export default defineComponent({
  components: { BoxEdit },
  props: {
    label: {
      type: String,
      required: true,
    },
    currentItem: Object,
    selectItemsList: Object,
    fieldToUpdateInDB: { type: String, required: true },
    iconPrefix: {
      required: false,
      type: String,
    },
  },
  setup(props, context) {
    let showEditModal = ref(false);
    const updatedValue = ref(props.currentItem);
    const route = useRoute();
    const submitUpdatedValue = () => {
      supabase
        .from<definitions["tickets"]>("tickets")
        .update({ [props.fieldToUpdateInDB]: updatedValue.value })
        .match({ key: route.params.ticketKey })
        .then(({ data }) => {
          data &&
            context.emit(
              "update:currentItem",
              props.selectItemsList!.find((x) => x.id == updatedValue.value)
            );
        });
    };
    return { showEditModal, updatedValue, submitUpdatedValue };
  },
});
