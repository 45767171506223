
import { defineComponent, ref } from "@vue/runtime-core";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    currentItem: { type: Object, required: true },
    iconPrefix: {
      required: false,
      type: String,
    },
    handleUpdateItem: {
      type: Function,
      required: true,
    },
    handleDeleteItem: {
      type: Function,
      required: true,
    },
    handleSaveItem: {
      type: Function,
      required: true,
    },
  },
  setup(props, ctx) {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const updatedValue = ref<any>({});

    const editItem = (itemToEdit) => {
      updatedValue.value = { ...itemToEdit };
    };
    const saveItem = (item) => {
      const action =
        item.id != -1 ? props.handleUpdateItem : props.handleSaveItem;
      action(item).then((newId) => {
        const indexUpdated = props.currentItem.findIndex(
          (x) => x.id == item.id
        );
        const newCurrentItem = props.currentItem;
        newCurrentItem[indexUpdated] = {
          id: newId || updatedValue.value.id,
          name: updatedValue.value.name,
        };
        ctx.emit("update:currentItem", newCurrentItem);
        updatedValue.value = {};
      });
    };
    const deleteItem = (item) => {
      props.handleDeleteItem(item).then(() => {
        const newCurrentItem = props.currentItem.filter((x) => x.id != item.id);
        ctx.emit("update:currentItem", newCurrentItem);
      });
    };
    const addRow = () => {
      if (!props.currentItem.find((x) => x.id == -1))
        ctx.emit(
          "update:currentItem",
          props.currentItem.concat({ name: "", id: -1 })
        );
      updatedValue.value = { name: "", id: -1 };
    };
    return { updatedValue, deleteItem, editItem, saveItem, addRow };
  },
});
