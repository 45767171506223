import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-399c338c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "row bg-white p-4 justify-content-between border-radius-top card-body p-lg-15 pb-lg-0" }
const _hoisted_3 = { class: "col-lg-9 p-4" }
const _hoisted_4 = { class: "header__desc" }
const _hoisted_5 = { class: "header__desc__left" }
const _hoisted_6 = { class: "text-primary pb-2" }
const _hoisted_7 = { class: "header__desc__right" }
const _hoisted_8 = { class: "fw-bolder" }
const _hoisted_9 = { class: "mb-9 d-flex mt-9 nav-wrap" }
const _hoisted_10 = { class: "body__desc" }
const _hoisted_11 = { class: "row bg-white p-4 border-radius-bottom card-body p-lg-15 pb-lg-0 pt-lg-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectEditInput = _resolveComponent("SelectEditInput")!
  const _component_CalendarEditInput = _resolveComponent("CalendarEditInput")!
  const _component_TextAreaEditInput = _resolveComponent("TextAreaEditInput")!
  const _component_MultiTextEditInput = _resolveComponent("MultiTextEditInput")!
  const _component_MultiFileInput = _resolveComponent("MultiFileInput")!
  const _component_Aside = _resolveComponent("Aside")!
  const _component_Comments = _resolveComponent("Comments")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_History = _resolveComponent("History")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("header", null, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h6", _hoisted_6, _toDisplayString(_ctx.targetData.platformName) + ": " + _toDisplayString(_ctx.targetData.projectName) + " / " + _toDisplayString(_ctx.targetData.key), 1),
              _createElementVNode("h3", null, _toDisplayString(_ctx.targetData.title), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, "Log time: " + _toDisplayString(_ctx.targetData.timeSpent), 1)
            ])
          ]),
          _createElementVNode("nav", _hoisted_9, [
            _createVNode(_component_SelectEditInput, {
              label: "Assigned User",
              "select-items-list": _ctx.platformUsers,
              "current-item": _ctx.targetData.assignedUser,
              "onUpdate:current-item": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.targetData.assignedUser) = $event)),
              "field-to-update-in-d-b": "assignedUserId"
            }, null, 8, ["select-items-list", "current-item"]),
            _createVNode(_component_SelectEditInput, {
              label: "Priorities",
              "select-items-list": _ctx.priorities,
              "icon-prefix": "ticket-priorities",
              "current-item": _ctx.targetData.priority,
              "onUpdate:current-item": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.targetData.priority) = $event)),
              "field-to-update-in-d-b": "priorityId"
            }, null, 8, ["select-items-list", "current-item"]),
            _createVNode(_component_SelectEditInput, {
              label: "Complexities",
              "select-items-list": _ctx.complexities,
              "icon-prefix": "ticket-complexities",
              "current-item": _ctx.targetData.complexity,
              "onUpdate:current-item": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.targetData.complexity) = $event)),
              "field-to-update-in-d-b": "complexityId"
            }, null, 8, ["select-items-list", "current-item"]),
            _createVNode(_component_SelectEditInput, {
              label: "Statues",
              "select-items-list": _ctx.statues,
              "current-item": _ctx.targetData.status,
              "onUpdate:current-item": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.targetData.status) = $event)),
              "field-to-update-in-d-b": "statusId"
            }, null, 8, ["select-items-list", "current-item"]),
            _createVNode(_component_CalendarEditInput, {
              label: "Due Date",
              "current-item": _ctx.targetData.dueDate,
              "onUpdate:current-item": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.targetData.dueDate) = $event)),
              "field-to-update-in-d-b": "dueDate"
            }, null, 8, ["current-item"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_TextAreaEditInput, {
              "current-item": _ctx.targetData.description,
              "onUpdate:current-item": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.targetData.description) = $event)),
              "field-to-update-in-d-b": "description",
              label: "Description"
            }, null, 8, ["current-item"]),
            _createVNode(_component_MultiTextEditInput, {
              "current-item": _ctx.targetData.userJourney,
              "onUpdate:current-item": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.targetData.userJourney) = $event)),
              label: "userJourney",
              "handle-save-item": _ctx.saveUserJourney,
              "handle-delete-item": _ctx.deleteUserJourney,
              "handle-update-item": _ctx.updateUserJourney
            }, null, 8, ["current-item", "handle-save-item", "handle-delete-item", "handle-update-item"]),
            _createVNode(_component_MultiTextEditInput, {
              "current-item": _ctx.targetData.userJourney,
              "onUpdate:current-item": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.targetData.userJourney) = $event)),
              label: "acceptanceTest",
              "handle-save-item": _ctx.saveUserJourney,
              "handle-delete-item": _ctx.deleteUserJourney,
              "handle-update-item": _ctx.updateUserJourney
            }, null, 8, ["current-item", "handle-save-item", "handle-delete-item", "handle-update-item"]),
            _createVNode(_component_MultiFileInput, {
              modelValue: _ctx.targetData.attachments,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.targetData.attachments) = $event))
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _createVNode(_component_Aside, {
        estimation: _ctx.targetData.estimation,
        timeSpent: _ctx.targetData.timeSpent,
        skills: _ctx.targetData.skills,
        "onUpdate:skills": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.targetData.skills) = $event)),
        type: _ctx.targetData.type,
        "onUpdate:type": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.targetData.type) = $event)),
        relatesPage: _ctx.targetData.relatesPage,
        "onUpdate:relatesPage": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.targetData.relatesPage) = $event)),
        relatesSection: _ctx.targetData.relatesSection,
        "onUpdate:relatesSection": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.targetData.relatesSection) = $event)),
        assignedUser: _ctx.targetData.assignedUser,
        createdAt: _ctx.targetData.createdAt,
        show: _ctx.show,
        onUpdateShow: _cache[13] || (_cache[13] = ($event: any) => (_ctx.show = 6)),
        onUpdateShowPages: _cache[14] || (_cache[14] = ($event: any) => (_ctx.show = 7)),
        onUpdateShowSection: _cache[15] || (_cache[15] = ($event: any) => (_ctx.show = 8)),
        onResetShow: _cache[16] || (_cache[16] = ($event: any) => (_ctx.cancelEdit(5))),
        onResetShowPages: _cache[17] || (_cache[17] = ($event: any) => (_ctx.cancelEdit(6))),
        onResetShowSection: _cache[18] || (_cache[18] = ($event: any) => (_ctx.cancelEdit(7))),
        onConfirmationPages: _cache[19] || (_cache[19] = ($event: any) => (_ctx.confirmationChange(6))),
        onConfirmationSection: _cache[20] || (_cache[20] = ($event: any) => (_ctx.confirmationChange(7)))
      }, null, 8, ["estimation", "timeSpent", "skills", "type", "relatesPage", "relatesSection", "assignedUser", "createdAt", "show"])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.activeTabName,
        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.activeTabName) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            label: "Comments",
            name: "CommentsPane"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Comments, {
                commentsData: _ctx.targetData.comments,
                onAddNewComment: _cache[21] || (_cache[21] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["commentsData"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, {
            label: "History",
            name: "HistoriesPane"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_History)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}