import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex flex-column mb-1 fv-row position-relative me-20" }
const _hoisted_2 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = {
  key: 0,
  class: "symbol symbol-20px"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "ms-5" }
const _hoisted_8 = { class: "fw-bold text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_BoxEdit = _resolveComponent("BoxEdit")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.label)), 1),
    _createVNode(_component_el_tooltip, {
      class: "item",
      effect: "dark",
      content: "Click to Edit",
      placement: "bottom"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", {
          onClick: _cache[0] || (_cache[0] = () => (_ctx.showEditModal = true)),
          class: "fs-6 mt-2 text-gray-700 cursor-pointer"
        }, _toDisplayString(_ctx.currentItem?.name), 1)
      ]),
      _: 1
    }),
    (_ctx.showEditModal)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_BoxEdit, {
            title: "priority",
            onConfirm: _ctx.submitUpdatedValue,
            onCancel: _cache[2] || (_cache[2] = () => (_ctx.showEditModal = false)),
            class: "box-edit"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.updatedValue,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.updatedValue) = $event)),
                placeholder: `Select a ${_ctx.label}`,
                as: "select"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectItemsList, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.id,
                      value: item.id,
                      label: item.name
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          (_ctx.iconPrefix)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createElementVNode("img", {
                                  src: `/svg/${_ctx.iconPrefix}/icon_${item.name.toLowerCase()}.svg`
                                }, null, 8, _hoisted_6)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("span", _hoisted_8, _toDisplayString(item.name), 1)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["value", "label"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["onConfirm"])
        ])), [
          [_directive_click_outside, () => (_ctx.showEditModal = false)]
        ])
      : _createCommentVNode("", true)
  ]))
}