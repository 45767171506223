
import { defineComponent, onMounted, ref, watch } from "vue";

import Comments from "@/views/main/ticketDetails/parts/Comments.vue";
import History from "@/views/main/ticketDetails/parts/History.vue";
import Aside from "@/views/main/ticketDetails/parts/Aside.vue";
import { setCurrentPageTitle } from "@/core/helpers/config";
import { supabase } from "@/service/SupabaseService";
import { definitions } from "@/types/supabase";
import { useRoute } from "vue-router";
import SelectEditInput from "@/components/inputs/SelectEditInput.vue";
import CalendarEditInput from "@/components/inputs/CalendarEditInput.vue";
import TextAreaEditInput from "@/components/inputs/TextAreaEditInput.vue";
import MultiTextEditInput from "@/components/inputs/MultiTextEditInput.vue";
import MultiFileInput from "@/components/inputs/MultiFileInput.vue";
interface TicketData {
  key?: string;
  title?: string;
  projectName?: string;
  platformName?: string;
  ticketTitle?: string;
  assignedUser?: definitions["users"];
  type?: Pick<definitions["ticket_types"], "id" | "name">;
  complexity?: Pick<definitions["ticket_complexities"], "id" | "name">;
  status?: Pick<definitions["ticket_statuses"], "id" | "name">;
  priority?: Pick<definitions["ticket_statuses"], "id" | "name">;
  dueDate?: string;
  description?: string;
  skills?: Array<string>;
  estimation?: string;
  userJourney: Pick<definitions["ticket_statuses"], "id" | "name">[];
  acceptanceTest: Array<string>;
  relatesPage?: string;
  relatesSection?: string;
  timeSpent?: number;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  attachments: Array<any>;
  comments: Array<any>;
  createdAt?: Date;
}

export default defineComponent({
  components: {
    Aside,
    History,
    Comments,
    SelectEditInput,
    CalendarEditInput,
    TextAreaEditInput,
    MultiTextEditInput,
    MultiFileInput,
  },
  setup() {
    let targetData = ref<TicketData>({
      skills: [],
      userJourney: [],
      acceptanceTest: [],
      attachments: [],
      comments: [],
    });
    let platformUsers = ref<any>();
    let activeTabName = ref<any>("CommentsPane");

    const route = useRoute();
    const fetchTicketDetails = async (id) => {
      const { data, error } = await supabase
        .from("tickets")
        .select(
          `
          id,
          key,
          title,
          description,
          dueDate,
          timeSpent,
          timeEstimated,
          statusId(id,name),
          complexityId(id,name),
          ticketTypeId(id, name),
          priorityId(id,name),
          createdAt,
          assignedUser:users!FK_tickets_assignedUserId(id,firstName,lastName,email),
          projects!FK_tickets_projectId(name, platforms(name)),
          ticket_acceptance_criterias(description),
          scenario_users(id,description),
          skills(name)
        `
        )
        .eq("key", id)
        .single();
      targetData.value = {
        projectName: data.projects?.name,
        title: data.title,
        key: data.key,
        type: data.ticketTypeId,
        platformName: data.projects.platforms.name,
        assignedUser: {
          ...data.assignedUser,
          name: `${data.assignedUser?.firstName} ${data.assignedUser?.lastName}`,
        },
        priority: data.priorityId,
        complexity: data.complexityId,
        status: data.statusId,
        dueDate: new Date(data.dueDate).toDateString(),
        ticketTitle: data.title,
        description: data.description,
        timeSpent: data.timeSpent,
        estimation: data.timeEstimated,
        skills: data.skills.map(({ name }) => name),
        acceptanceTest: data.ticket_acceptance_criterias.map(
          ({ description }) => description
        ),
        createdAt: new Date(data.createdAt),
        userJourney: data.scenario_users.map(({ id, description }) => ({
          id,
          name: description,
        })),
        relatesPage: "",
        relatesSection: "",
        attachments: [
          {
            name: "pdf-test.pdf",
            percentage: 0,
            status: "ready",
            size: 20597,
            uid: 1638974895671,
            url: "blob:http://141.94.19.43:8080/eb11245c-7ea9-4c5a-9061-af7fc3da149a",
          },
        ],
        comments: [
          {
            firstName: "Lucy",
            lastName: "Kunic",
            date: "0 min",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum nibh tellus molestie nunc non blandit massa.",
          },
          {
            firstName: "Lucy",
            lastName: "Kunic",
            date: "0 min",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum nibh tellus molestie nunc non blandit massa.",
          },
        ],
      };
      return { data, error };
    };
    const fetchPlatformUsers = async (ticketKey) => {
      const platformKey = ticketKey.split("-")[0];
      await supabase
        .from("platforms")
        .select("id,groups(users(*))")
        .eq("ticketsPrefix", platformKey)
        .single()
        .then(({ data }: any) => {
          let groupUsers = data.groups.reduce(
            (prev, current) => prev.concat(current.users),
            []
          );
          groupUsers = [
            ...new Map(groupUsers.map((item) => [item["id"], item])).values(),
          ];
          platformUsers.value = groupUsers.map(
            ({ id, firstName, lastName }) => ({
              id,
              name: `${firstName} ${lastName}`,
            })
          );
        });
    };
    watch(
      () => route.params.ticketKey,
      (value) => {
        if (value) {
          fetchTicketDetails(value);
          fetchPlatformUsers(value);
        }
      },
      { immediate: true }
    );
    onMounted(() => {
      setCurrentPageTitle("Ticket Details");
      fetchNamesListFromSupaBase(priorities, "priorities");
      fetchNamesListFromSupaBase(complexities, "ticket_complexities");
      fetchNamesListFromSupaBase(statues, "ticket_statuses");
    });

    const priorities = ref();
    const complexities = ref();
    const statues = ref();

    const fetchNamesListFromSupaBase = (state, table) => {
      supabase
        .from(table)
        .select("id,name")
        .then(({ data }) => {
          state.value = data;
        });
    };
    const updateUserJourney = async (e) => {
      await supabase
        .from<definitions["scenario_users"]>("scenario_users")
        .update({ description: e.name })
        .eq("id", e.id);
    };
    const deleteUserJourney = async (e) => {
      await supabase
        .from<definitions["scenario_users"]>("scenario_users")
        .update({ deletedAt: new Date().toUTCString() })
        .eq("id", e.id);
    };
    const saveUserJourney = async (e) => {
      const { data } = await supabase
        .from<definitions["scenario_users"]>("scenario_users")
        .insert({ description: e.name })
        .single();

      return data?.id;
    };

    return {
      targetData,
      priorities,
      complexities,
      statues,
      platformUsers,
      activeTabName,
      saveUserJourney,
      deleteUserJourney,
      updateUserJourney,
    };
  },
});
