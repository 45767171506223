import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-509e08e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "ticket-development-pargraph" }
const _hoisted_3 = { class: "ticket-development-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "ticket-development-container",
    style: _normalizeStyle(`background: ${_ctx.background}`),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("img", {
      class: "ticket-development-img",
      src: _ctx.src,
      alt: _ctx.alt
    }, null, 8, _hoisted_1),
    _createElementVNode("p", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.description), 1)
    ]),
    _createElementVNode("span", {
      class: "ticket-development-count",
      style: _normalizeStyle(`color: ${_ctx.colorCount}`)
    }, _toDisplayString(_ctx.itemCount), 5)
  ], 4))
}