import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61e97306"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "boxEdit" }
const _hoisted_2 = { class: "editing-btns mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "svg-icon svg-icon-1 me-2",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirm')))
      }, [
        _createVNode(_component_inline_svg, { src: "svg/icons/check.svg" })
      ]),
      _createElementVNode("span", {
        class: "svg-icon svg-icon-1",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
      }, [
        _createVNode(_component_inline_svg, { src: "svg/icons/cancel.svg" })
      ])
    ])
  ]))
}