import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column mb-1 fv-row position-relative me-20" }
const _hoisted_2 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_BoxEdit = _resolveComponent("BoxEdit")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.label)), 1),
    _createVNode(_component_el_tooltip, {
      class: "item",
      effect: "dark",
      content: "Click to Edit",
      placement: "bottom"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", {
          onClick: _cache[0] || (_cache[0] = () => (_ctx.showEditModal = true)),
          class: "fs-6 mt-2 text-gray-700 cursor-pointer"
        }, _toDisplayString(_ctx.currentItem), 1)
      ]),
      _: 1
    }),
    (_ctx.showEditModal)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_BoxEdit, {
            title: "priority",
            onConfirm: _ctx.submitUpdatedValue,
            onCancel: _cache[2] || (_cache[2] = () => (_ctx.showEditModal = false)),
            class: "box-edit"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_date_picker, {
                modelValue: _ctx.updatedValue,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.updatedValue) = $event)),
                placeholder: "Select a date",
                name: "dueDate",
                format: "YYYY/MM/DD",
                "value-format": "YYYY-MM-DD"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["onConfirm"])
        ])), [
          [_directive_click_outside, () => (_ctx.showEditModal = false)]
        ])
      : _createCommentVNode("", true)
  ]))
}