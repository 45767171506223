import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-6 mt-9" }
const _hoisted_2 = { class: "d-flex flex-column fv-row mt-2 me-4" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = {
  key: 0,
  class: "pointer"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "svg-icon" }
const _hoisted_9 = { class: "editing-btns user-journey-btns mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t(_ctx.label)), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentItem, (textField, index) => {
        return (_openBlock(), _createBlock(_component_el_form_item, {
          key: index,
          class: "mw-50 overflow-hidden"
        }, {
          default: _withCtx(() => [
            (textField.id !== _ctx.updatedValue.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_el_tooltip, {
                    class: "item",
                    effect: "dark",
                    content: "Click to Edit",
                    placement: "bottom"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", {
                        onClick: ($event: any) => (_ctx.editItem(textField)),
                        class: "fs-6 mb-0 text-gray-700"
                      }, _toDisplayString(textField.name), 9, _hoisted_5)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              : _createCommentVNode("", true),
            (textField.id === _ctx.updatedValue.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.updatedValue.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.updatedValue.name) = $event)),
                    placeholder: "Enter Acceptance Test"
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("span", {
                    onClick: _withModifiers(($event: any) => (_ctx.deleteItem(textField)), ["prevent"]),
                    class: "user-journey-icon"
                  }, [
                    _createElementVNode("span", _hoisted_8, [
                      _createVNode(_component_inline_svg, { src: "svg/icons/delete.svg" })
                    ])
                  ], 8, _hoisted_7),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("span", {
                      class: "svg-icon svg-icon-1 me-2",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveItem(_ctx.updatedValue)))
                    }, [
                      _createVNode(_component_inline_svg, { src: "svg/icons/check.svg" })
                    ]),
                    _createElementVNode("span", {
                      class: "svg-icon svg-icon-1",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatedValue.id = null))
                    }, [
                      _createVNode(_component_inline_svg, { src: "svg/icons/cancel.svg" })
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    (_ctx.updatedValue.id != -1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "fw-bolder link-primary cursor-pointer ms-4 w-80px mt-n5",
          onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addRow && _ctx.addRow(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.$t("addItem")), 1))
      : _createCommentVNode("", true)
  ]))
}