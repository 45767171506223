
import { supabase } from "@/service/SupabaseService";
import { definitions } from "@/types/supabase";
import { defineComponent, ref, watch } from "@vue/runtime-core";
import { useRoute } from "vue-router";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    currentItem: String,
    fieldToUpdateInDB: { type: String, required: true },
    iconPrefix: {
      required: false,
      type: String,
    },
  },
  setup(props, context) {
    let showEditModal = ref(false);
    const updatedValue = ref<string | undefined>("");
    watch(
      () => props.currentItem,
      (val) => {
        if (val) updatedValue.value = val;
      }
    );
    const route = useRoute();
    const submitUpdatedValue = () => {
      supabase
        .from<definitions["tickets"]>("tickets")
        .update({ [props.fieldToUpdateInDB]: updatedValue.value })
        .match({ key: route.params.ticketKey })
        .then(({ data }) => {
          data &&
            updatedValue.value &&
            context.emit("update:currentItem", updatedValue.value);
          showEditModal.value = false;
        });
    };
    return { showEditModal, updatedValue, submitUpdatedValue };
  },
});
