
import { defineComponent, ref, computed } from "vue";
import Avatar from "../../../../components/shared/Avatar.vue";
export default defineComponent({
  name: "comments",
  components: { Avatar },
  props: {
    commentsData: Array,
  },
  setup(props, context) {
    const editing = ref(false);
    const tempValue = ref("");

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const inputComment = ref<any>();
    const previousComment = ref<string>();
    const comments = props.commentsData as Array<any>;
    const cancelEditing = (index) => {
      comments[index].desc = previousComment.value;
      editing.value = false;
    };
    const saveEditComment = () => {
      editing.value = false;
    };
    const addComment = () => {
      const newComment = {
        firstName: "Lucy",
        lastName: "Kunic",
        date: "0 min",
        desc: tempValue.value,
      };
      context.emit("addNewComment", newComment);
      cancelAddComment();
    };
    const cancelAddComment = () => {
      inputComment.value.clear();
    };
    const enableEditing = (index) => {
      previousComment.value = comments[index].desc;

      editing.value = index;
    };
    const isDisable = computed(() => {
      const disable = tempValue.value.length ? false : true;
      return disable;
    });

    return {
      tempValue,
      isDisable,
      cancelEditing,
      addComment,
      inputComment,
      enableEditing,
      editing,
      saveEditComment,
      cancelAddComment,
    };
  },
});
