import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86ece7cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column mb-1 fv-row position-relative me-20" }
const _hoisted_2 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "editing-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.label)), 1),
    (!_ctx.showEditModal)
      ? (_openBlock(), _createBlock(_component_el_tooltip, {
          key: 0,
          class: "item",
          effect: "dark",
          content: "Click to Edit",
          placement: "bottom"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", {
              onClick: _cache[0] || (_cache[0] = () => (_ctx.showEditModal = true)),
              class: "fs-6 mt-2 text-gray-700 cursor-pointer"
            }, _toDisplayString(_ctx.currentItem), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showEditModal)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_el_input, {
            type: "textarea",
            autosize: "",
            modelValue: _ctx.updatedValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.updatedValue) = $event)),
            class: "input d-block mb-4 w-100 border-textarea"
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", {
              class: "svg-icon svg-icon-1 me-2",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.submitUpdatedValue && _ctx.submitUpdatedValue(...args)))
            }, [
              _createVNode(_component_inline_svg, { src: "svg/icons/check.svg" })
            ]),
            _createElementVNode("span", {
              class: "svg-icon svg-icon-1",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showEditModal = false))
            }, [
              _createVNode(_component_inline_svg, { src: "svg/icons/cancel.svg" })
            ])
          ])
        ])), [
          [_directive_click_outside, () => (_ctx.showEditModal = false)]
        ])
      : _createCommentVNode("", true)
  ]))
}